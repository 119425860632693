@import "~@o4c/css/src/foundation";
@import "~@o4c/css/src/core/fonts";
@import "~@o4c/css/src/components/buttons";
@import "~@o4c/css/src/components/text-fields";
@import "~@o4c/css/src/components/toggle-groups";
@import "~@o4c/css/src/components/cards";
@import "~@o4c/css/src/components/dropdowns";
@import "~@o4c/css/src/components/switches";
@import "~@o4c/css/src/components/tooltips";

body {
    background-color: #f6f6f6;
 }

