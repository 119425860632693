.errorsContainer {
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }
 
 .errorDetails {
    margin-top: 24px;
    padding: 16px;
    background: var(--grey-lighter);
    border-radius: var(--card-border-radius);
 }

 .textContainer {
    display: flex;
    flex-direction: column;
    
    text-align: center;
 }

 p {
    color: red;
    font-size: 35px;
 }
 