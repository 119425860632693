.tile {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: white;
    width: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    margin-top: 2px;
}
.tile:hover{
    background-color: whitesmoke;
}

.tileTitle {
    margin: 8px;
    margin-left: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--grey-dark);
    font-size: small;
}

.iconTitle {
    margin: 12px;
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.card {
    width: 80%;
    height: 80%;
}

.mainContent {
    margin: 10px;
    white-space: pre-wrap;
    overflow: scroll;
    height: 87%;
    overflow-x: hidden;
}

.tileTitlePopup {
    font-size: medium;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dialogCloseButton {
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}

.CardHeader {
    background: white;
}

@media screen and (max-width: 930px) {
    .mainContent {
        white-space: pre-line;

    }
}