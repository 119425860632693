.headerContainer {
    display: flex;
    background-color: white;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    color: #222222;
    margin-bottom: 24px;
}

.headerTitle {
    margin-left: 8px;
}